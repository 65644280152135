import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import deLocale from "date-fns/locale/de";
import { format, formatDistanceToNowStrict } from "date-fns";
import objectID from "bson-objectid";
import { json } from "@remix-run/node";
import { useEffect, useState } from "react";

export function requireObjectId(param?: string, error = "Ungültige Anfrage."): asserts param {
  if (!param || !objectID.isValid(param)) throw json({ error }, 400);
  return;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function relativeGermanDate(date: Date | string) {
  if (typeof date === "string") {
    date = new Date(date);
  }

  return formatDistanceToNowStrict(date, {
    locale: deLocale,
    addSuffix: true,
  });
}

export function formatGermanDate(date: Date | string) {
  if (typeof date === "string") {
    date = new Date(date);
  }

  return format(date, "P", { locale: deLocale });
}

export function formatGermanDateTime(date: Date | string) {
  if (typeof date === "string") {
    date = new Date(date);
  }

  const rawDate = date.toLocaleString("de-DE", {
    dateStyle: "medium",
    timeStyle: "short",
    // are we sure this is correct for all users?
    timeZone: "Europe/Berlin",
  });

  return `${rawDate} Uhr`;
}

// can be used for HTML date inputs (e.g. <input type="date" />)
export function formatHtmlDate(date: Date | string) {
  if (typeof date === "string") {
    date = new Date(date);
  }
  return format(date, "yyyy-MM-dd");
}

export class TimeAttackDefender {
  startTime: number;
  targetDuration: number;

  constructor(targetTime = 1000) {
    this.startTime = Date.now();
    this.targetDuration = targetTime;
  }

  async wait() {
    const waitTime = Date.now() - this.startTime;
    if (waitTime < this.targetDuration) {
      await new Promise((resolve) => setTimeout(resolve, this.targetDuration - waitTime + Math.random() * 100));
    }
  }
}

const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean" ? navigator.onLine : true;

export const useNavigatorOnLine = () => {
  const [status, setStatus] = useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  return status;
};
